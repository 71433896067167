<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <!-- User Info: Left col -->
      <b-col md="6" class="d-flex justify-content-between flex-column">
        <b-card class="h-100 cardH">
          <!-- User Avatar & Action Buttons -->

          <b-row>
            <b-col cols="12" class="mt-2">
              <div class="d-flex justify-content-start">
                <CoolLightBox
                  :items="profileArr"
                  :index="imgIndex"
                  @close="imgIndex = null"
                >
                </CoolLightBox>
                <!-- <b-img
                  @click="imgIndex = 0"
                  rounded
                  :src="vendor.profile_picture"
                  :alt="vendor.firstname"
                  style="height: 200px; width: auto;"
                /> -->
                <!-- vendor -->
                <div class="d-flex flex-column ml-1" v-if="vendor != null">
                  <div class="">
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >First Name:
                      </span>
                     
                      <router-link  :to="{ path: '/User/Show/' + vendor.id }" 
                      v-if="authPermissions.includes('user')  && vendor.firstname != undefined">
                      {{ vendor.firstname }}
                      </router-link>
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >Last Name:
                      </span>
                      {{ vendor.lastname }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >Email:
                      </span>
                      {{ vendor.email }}
                    </h5>

                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >Birthdate:
                      </span>
                      {{ vendor.birthdate }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >ID Number:
                      </span>
                      {{ vendor.idnumber }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >Code:
                      </span>
                      {{ vendor.code }}
                    </h5>
                  </div>
                </div>

                <!-- user  -->
                <div class="d-flex flex-column ml-1" v-if="user != null">
                  <div class="">
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >First Name:
                      </span>

                      <router-link  :to="{ path: '/User/Show/' + user.id }" 
                      v-if="authPermissions.includes('user')  && user.firstname != undefined">
                      {{ user.firstname }}
                      </router-link>

                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >Last Name:
                      </span>
                        <router-link  :to="{ path: '/User/Show/' + user.id }" 
                        v-if="authPermissions.includes('user')  && user.lastname != undefined">
                        {{ user.lastname }}
                        </router-link>
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >Email:
                      </span>
                      {{ user.email }}
                    </h5>

                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >Birthdate:
                      </span>
                      {{ user.birthdate }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >ID Number:
                      </span>
                      {{ user.idnumber }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >Code:
                      </span>
                      {{ user.code }}
                    </h5>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col class="mb-2" md="6">
        <b-card class="h-100">
          <div class="d-flex justify-content-center">
            <!-- update Status -->

            <b-button
              block
              v-if="authPermissions.includes('update')"
              :to="{ path: '/payout/change/status/' + payoutData.id }"
              variant="outline-success "
              class="mr-1 mt-1"
            >
              Change Status
            </b-button>

            <b-button
              block
              v-if="authPermissions.includes('update') && payoutData.vendor != null"
              :to="{ path: '/payout/update/' + payoutData.id }"
              variant="outline-warning "
              class="mr-1 mt-1"
            >
              Update
            </b-button>
          </div>
          <hr />
          <div
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ payoutData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Updated at
              </h6>
              <h3 class="mb-0">
                {{ payoutData.updated_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                status
              </h6>
              <h3 class="mb-0">
                <b-badge variant="primary">{{ payoutData.status }} </b-badge>
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card title="Files" v-if="payoutData.file != null">
      <table id="table">
        <tr>
          <th> <h4> Link</h4></th>
        </tr>
        <tr>
        <td> <a class="text-primary" target="_blank" :href="payoutData.file">Press here to open file</a> </td>
        </tr>
      </table>
    </b-card>

    
    <div class="d-flex justify-content-between flex-column" v-if="payoutData.payment_session   &&  payoutData.payment_session.length != 0">
        <!-- User Avatar & Action Buttons -->
        <b-card title="Payment Session" class="h-100">
        <b-table
            id="PayoutsTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            class="position-relative"
            :items="payoutData.payment_session"
            :fields="fields"
          >
          <template #cell(status)="data">
              <b-badge variant="primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(vendor)="data">
              <router-link :to="{ path: '/Vendor/Show/' + data.value.id }" 
              v-if="authPermissions.includes('vendor')">
                {{data.value.storename}}
              </router-link>
            </template>



           <template #cell(payout_type)="data">
              <b-badge v-if="data.item.seller_id != null" variant="primary">
                   User
              </b-badge>


              <b-badge v-if="data.buyer_id != null" variant="primary">
                   Seller
              </b-badge>
                  
                  
            </template>

            
         
          
          </b-table>
          </b-card>

          
      </div>

    <b-card>

      <div class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->
        <b-card title="Total Payment" class="h-100" v-if="payoutData.user != null">
          <p class="mb-2 h2">Total: {{ payoutData.price }} EGP</p>
        </b-card>



        <b-card title="Orders" class="h-100" v-if="payoutData.vendor != null">
          <p class="mb-2 h2">Total: {{ payoutData.price }} EGP</p>
          <b-row>
            <b-col
              cols="12"
              v-for="(item, index) in payoutData.orders"
              :key="index"
            >
              <p class="mb-2 h3">
                {{
                  'Code: ' +
                  item.code +
                  ' ' +
                  '- ' +
                  ' ' +
                  'Amount: Worthy ' +
                  item.payout_total +
                  ' ' +
                  'EGP' +
                  ' ' +
                  '| Date: ' +
                  item.created_at
                }}
              </p>
            </b-col>
          </b-row>
        </b-card>


      

      </div>
    </b-card>
    <b-card title="Notes">
      <b-row>
        <b-col cols="12">
          <label for="textarea-default">Note</label>
          <b-form-textarea
            id="textarea-default"
            v-model="loggerNote.note"
            placeholder="Note"
            rows="3"
          />
          <b-button
            variant="primary"
            class="mt-1"
            @click="storeLogger(payoutData.id)"
            >Submit</b-button
          >
        </b-col>
        <b-col class="mt-3 ml-5" cols="12">
          <app-timeline>
            <app-timeline-item
              v-for="(item, index) in payoutData.loggers"
              :key="index"
              :variant="item.admin != null ? 'primary' : 'success'"
            >
              <div
                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
              >
                <h6>{{ item.note }}</h6>
              </div>
              <b-media v-if="item.admin != null">
                <template #aside>
                  <b-avatar :src="item.admin.profile_picture" />
                </template>
                <h6>{{ item.admin.firstname + ' ' + item.admin.lastname }}</h6>
                <p>{{ item.admin.role }}</p>
              </b-media>
              <b-media v-if="item.vendor != null">
                <template #aside>
                  <b-avatar :src="item.vendor.profile_picture" />
                </template>
                <h6>{{ item.vendor.storename }}</h6>
              </b-media>
              <b-media v-if="item.user != null">
                <template #aside>
                  <b-avatar :src="item.user.profile_picture" />
                </template>
                <h6>{{ item.user.storename }}</h6>
              </b-media>
            </app-timeline-item>
          </app-timeline>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

// import axios from '@/libs/axios'
export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    CoolLightBox,
  },
  data() {
    return {
      authPermissions: [],
      id: 0,
      errors_back: [],
      showDismissibleAlert: false,
      payoutData: [],
      loggerNote: {
        note: '',
      },
      imgIndex: null,
      profileArr: [],
      vendor: {},
      user: {},
      // table fildes
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'Code',
          sortable: true,
        },
        {
          key: 'payout_type',
          label: 'Payout Type',
        },
        {
          key: 'status',
          label: 'status',
        },
        {
          key: 'start_date',
          label: 'Start Date',
        },

        {
          key: 'total_paid',
          label: 'Total Paid',
        },
      
      ],
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    // fetch data of value
    this.id = this.$route.params.id
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get('payouts/' + this.id)
        .then((result) => {
          this.payoutData = result.data.data;
          this.user = this.payoutData.user;
          this.vendor = this.payoutData.vendor;

        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    storeLogger(id) {
      axios
        .post('payouts/' + id + '/store-logger', this.loggerNote)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // delete translation function
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
